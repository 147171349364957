import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-global-settings-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_global_settings_section = _resolveComponent("bp-global-settings-section")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_settings.jpg",
      icon: ['fad','gears']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Global settings")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "tabs-position": "inside",
      "fill-height": "",
      "fill-width": "",
      tabs: _ctx.tabs,
      loading: _ctx.store.isLoading() || _ctx.tabSlots.length === 0
    }, _createSlots({
      "tabs-header": _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "arrows-rotate",
          color: "yellow",
          onClick: _ctx.store.reload
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Refresh")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      "tabs-footer": _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          type: "save",
          action: _ctx.saveConfig
        }, null, 8, ["action"])
      ]),
      _: 2
    }, [
      _renderList(_ctx.tabSlots, ([slotName, key, value]) => {
        return {
          name: slotName,
          fn: _withCtx(({ activeTabId }) => [
            _createElementVNode("form", {
              class: "flex-column gap-md",
              onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createVNode(_component_bp_global_settings_section, {
                path: key,
                section: value,
                "onUpdate:section": ($event: any) => (_ctx.updateConfig(key, $event)),
                activeTabId: activeTabId
              }, null, 8, ["path", "section", "onUpdate:section", "activeTabId"])
            ], 32)
          ])
        }
      })
    ]), 1032, ["tabs", "loading"])
  ]))
}