
import { $gettext } from 'vue-gettext';
import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { Config, ConfigSection, useConfigStore } from '@/stores/config';
import { defineComponent, ref, watch } from 'vue';
import { Icon } from '@/components/icon/BpIcon';
import { toTitleCase } from '@/utils/string';
import BpGlobalSettingsSectionVue from '@/components/settings/BpGlobalSettingsSection.vue';
import BpToast from '@/components/toast/BpToasts';
import clone from '@sahnee/clone';
import deepMerge, { deepValue, Flat } from '@/utils/object';

export default defineComponent({
  name: 'bp-global-settings-view',
  components: {
    BpGlobalSettingsSection: BpGlobalSettingsSectionVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useConfigStore();

    const config = ref<Config>({});
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      config.value = clone(store.config);
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// TABS
    ///-------------------------------------------------------------------

    const tabs = ref<Flat<BaseDoc>[]>([]);
    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      window.setTimeout(() => {
        tabs.value = store.getSections()
          .filter(section => !(section._id as string).startsWith('cookies.'))
          .map(section => ({ ...section, icon: tabIcon(section) })) as Flat<BaseDoc & {icon: Icon}>[];
      }, 0);
    }, { immediate: true })

    function tabIcon(section: ConfigSection) {
      if ((section._id as string).startsWith('cookie')) { return ['far', 'cookie-bite'] }
      if ((section._id as string).startsWith('email')) { return ['far', 'envelope'] }
      if ((section._id as string).startsWith('webinar')) { return ['far', 'video'] }
      return ['far', 'gear'];
    }

    const tabSlots = ref<[string, string, ConfigSection][]>([]);
    watch(() => [store.isLoading(), config.value], () => {
      if (!store.loaded || store.isLoading()) { return }
      window.setTimeout(() => {
        tabSlots.value = store.getSections()
          .map(tab => ['tab-content-' + tab._id, tab._id, deepValue(config.value, tab._id as string)]) as [string, string, ConfigSection][];
      }, 0);
    }, { immediate: true, deep: true })

    ///-------------------------------------------------------------------
    /// DATA
    ///-------------------------------------------------------------------

    const data = ref<[string, ConfigSection][]>(Object.entries(config.value));
    watch(() => config.value, cfg => {
      data.value = Object.entries(cfg).sort(([keyA], [keyB]) => {
        if (keyA === 'general') {
          return -1;
        }
        if (keyB === 'general') {
          return 1;
        }
        return keyA < keyB ? -1 : 1;
      });
    }, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// UPDATE CONFIG
    ///-------------------------------------------------------------------

    function updateConfig(keyString: string, value: string) {
      const keys = keyString.split('.');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = {};
      let nested = obj;
      let key = '';
      let idx = 0;
      while (idx < keys.length) {
        key = keys[idx];
        if (idx === keys.length - 1) {
          nested[key] = value
        } else {
          nested[key] = {};
          nested = nested[key];
        }
        idx++
      }
      config.value = deepMerge(config.value, obj) as Config;
    }

    async function saveConfig() {
      const response = await store.update(config.value);
      if (response?.success && response.data) {
        config.value = response.data.config;
        BpToast.show({
          color: 'green',
          title: $gettext('Settings successfully updated'),
          content: $gettext('The global settings were successfully updated.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to update settings'),
          content: $gettext('The global settings could not be updated: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    function getName(key: string) {
      return toTitleCase(key.replaceAll('_', ' ').trim());
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      config,
      data,
      tabs,
      tabSlots,
      deepValue,
      getName,
      store,
      saveConfig,
      updateConfig,
    }
  }
})
