
import { ConfigSection, useConfigStore } from '@/stores/config';
import { defineComponent, PropType, ref, watch } from 'vue';
import { isTranslated } from '@/translation';
import { marked as parseMarkdown } from 'marked';
import { toTitleCase } from '@/utils/string';

export default defineComponent({
  name: 'bp-global-settings-section',
  props: {
    indent: {
      type: Number,
      default: 0,
    },
    path: {
      type: String,
      required: true,
    },
    section: {
      type: Object as PropType<ConfigSection>,
      required: true,
    },
    activeTabId: String
  },
  emits: [
    'update:section'
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useConfigStore();

    ///-------------------------------------------------------------------
    /// DATA
    ///-------------------------------------------------------------------

    const data = ref<[string, ConfigSection | string][]>(Object.entries(props.section));
    watch(() => props.section, section => {
      data.value = [
        ...Object.entries(section)
          .filter(([, value]) => typeof value === 'string' || isTranslated(value))
          .sort(([keyA], [keyB]) => keyA < keyB ? -1 : 1),
        ...Object.entries(section)
          .filter(([, value]) => typeof value !== 'string' && !isTranslated(value))
          .sort(([keyA], [keyB]) => keyA < keyB ? -1 : 1),
      ]
    }, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// COMPONENT
    ///-------------------------------------------------------------------

    function emit(value: ConfigSection) {
      ctx.emit('update:section', value);
    }

    function getComponent(key: string, value?: unknown) {
      const allComments = store.getComments(`${props.path}.${key}`);
      const meta = allComments.filter(comment => comment.startsWith(';')).join('').substring(1).split(';');
      const comments = allComments.filter(comment => !comment.startsWith(';'));
      const componentProps = value !== undefined ? {
        modelValue: value,
        text: comments.length > 0 ? parseMarkdown(comments.join('\n\n')).replace(/\s-\s/g, '&ensp;&ndash;&ensp;') : undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'onUpdate:model-value': (event: any) => emit({ ...props.section, [key]: typeof event === 'string' ? event : typeof event !== 'object' ? event.toString() : event }),
        labelPosition: 'top',
        type: meta !== undefined && meta.length >= 1 ? meta[0] : undefined,
        min: meta !== undefined && meta.length >= 2 ? meta[1] : undefined,
        max: meta !== undefined && meta.length >= 3 ? meta[2] : undefined,
        step: meta !== undefined && meta.length >= 4 ? meta[3] : undefined,
      } : {};

      switch (key.toLowerCase()) {
        case 'template': {
          if (isTranslated(value)) {
            return {
              component: 'bp-wysiwyg-editor',
              props: { ...componentProps },
            }
          }
          return {
            component: 'bp-code-editor',
            props: { ...componentProps },
          }
        }
        case 'cookies':
        case 'email_reminder_interval': {
          return {
            component: 'bp-select',
            props: {
              ...selectProps(key, value, true),
              text: comments.length > 0 ? parseMarkdown(comments.join('\n\n')).replace(/\s-\s/g, '&ensp;&ndash;&ensp;') : undefined,
            },
          }
        }
        case 'recipients': {
          return {
            component: 'bp-select',
            props: {
              ...selectProps(key, value),
              text: comments.length > 0 ? parseMarkdown(comments.join('\n\n')).replace(/\s-\s/g, '&ensp;&ndash;&ensp;') : undefined,
            },
          }
        }
        case 'profanity_filter': {
          return {
            component: 'bp-input',
            props: {
              ...componentProps,
              type: 'password',
              autocomplete: 'off'
            }
          }
        }
        default: {
          return {
            component: 'bp-input',
            props: { ...componentProps }
          }
        }
      }
    }

    function selectProps(key: string, value: unknown, clearable = false) {
      const array = (value as string).split(',');
      return {
        clearable,
        createNewOnEnter: true,
        multiple: true,
        ...(value !== undefined ? {
          modelValue: array.length === 1 && !array[0] ? [] : array,
          'onUpdate:model-value': (event: string[]) => emit({ ...props.section, [key]: event.join(',') }),
        } : {}),
        text: undefined,
        labelPosition: 'top',
      }
    }

    ///-------------------------------------------------------------------
    /// NAME
    ///-------------------------------------------------------------------

    function getName(key: string) {
      if (props.path === 'cookies' && key !== 'cookies') { return key }
      return toTitleCase(key.replaceAll('_', ' ').trim());
    }

    ///-------------------------------------------------------------------
    /// TEMPLATE & IFRAME PREVIEW
    ///-------------------------------------------------------------------

    function isTemplate(key: string, value: unknown) {
      return key.toLowerCase() === 'template' && !isTranslated(value);
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      data,
      getComponent,
      getName,
      isTemplate,
      isTranslated,
    }
  }
})
