import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createSlots as _createSlots, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_iframe = _resolveComponent("bp-iframe")!
  const _component_bp_global_settings_section = _resolveComponent("bp-global-settings-section", true)!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, ([key, value]) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
      (typeof value === 'string' || _ctx.isTranslated(value))
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponent(key, value).component), _mergeProps({
            key: 0,
            ref_for: true
          }, _ctx.getComponent(key, value).props, {
            indent: 1.125 * _ctx.indent,
            label: _ctx.getName(key)
          }), _createSlots({ _: 2 }, [
            (_ctx.isTemplate(key, value))
              ? {
                  name: "text",
                  fn: _withCtx(() => [
                    (_ctx.getComponent(key, value).props.text)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.getComponent(key, value).props.text || ''
                        }, null, 8, _hoisted_1))
                      : _createCommentVNode("", true),
                    _createElementVNode("strong", null, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("Preview")
                        ])),
                        _: 1
                      })
                    ]),
                    (_openBlock(), _createBlock(_component_bp_iframe, {
                      key: _ctx.activeTabId,
                      "model-value": (_ctx.section[key] as string)
                    }, null, 8, ["model-value"]))
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1040, ["indent", "label"]))
        : (_ctx.path === 'cookies' || (!_ctx.data
      .map(([, val]) => typeof val === 'string' || _ctx.isTranslated(val))
      .includes(true) && value && typeof value === 'object' && !Array.isArray(value)))
          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
              key: 1,
              "intense-border": "",
              collapsible: "",
              collapsed: _ctx.path === 'cookies'
            }, {
              legend: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getName(key)), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_bp_global_settings_section, {
                  path: `${_ctx.path}.${key}`,
                  section: value,
                  indent: _ctx.indent + 1,
                  "onUpdate:section": ($event: any) => (_ctx.$emit('update:section', { ..._ctx.section, [key]: $event })),
                  "active-tab-id": _ctx.activeTabId
                }, null, 8, ["path", "section", "indent", "onUpdate:section", "active-tab-id"])
              ]),
              _: 2
            }, 1032, ["collapsed"]))
          : _createCommentVNode("", true)
    ], 64))
  }), 128))
}